import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import Hero from "../../../components/hero";
import ContactPerson from "../../../components/contactPerson";
import { Facts } from "../../../components/facts";
import { Count } from "../../../components/count";
import { ServicesGrid } from "../../../components/servicesGrid";
import { FloatTeaser } from "../../../components/floatTeaser";
import { Container } from "../../../components/container";

const HaiiloPartnership = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-haiilo_en.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      rene: file(relativePath: { eq: "profiles/rene-zoller.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
      haiilo: file(relativePath: { eq: "partner/haiilo.png" }) {
        ...pngTracedLogo
      }
    }

    fragment pngTracedLogo on File {
      childImageSharp {
        gatsbyImageData(
          width: 380
          placeholder: DOMINANT_COLOR
          layout: CONSTRAINED
        )
      }
    }
  `);

  return (
    <Layout lang="en" langPath="partner.haiilo.link">
      <Seo
        title="Haiilo Implementation Partner – Monday Consulting"
        description="As a Haiilo partner, we create a digital home for your employees. With the Employee Engagement Cloud, you always reach the right audience."
        keywords="Haiilo, Intranet, Employee Engagement Cloud, Stories, Coyo, Home, Employee Communications"
      />
      <div className="parallax-translate bg-shape-dots"></div>
      <Hero
        headline="The Employee Engagement Cloud"
        subline="We’re not only setting up an intranet platform for Haiilo but also creating a digital home for their employees."
        section="Haiilo Partner"
        styleClass="mb-lg-5"
        bgColor="green"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Haiilo"
        />
      </Hero>

      <FloatTeaser offset={60}>
        <div className="container">
          <h3 className="text-size-4">Coyo, Smarp and Jubiwee become Haiilo</h3>
          <p>
            A new, shared brand for three pioneers on a mission. These three
            companies from Finland, France and Germany have merged together to
            form a global provider of social intranet, staff communications and
            employee engagement software and services.
          </p>
        </div>
      </FloatTeaser>

      <div className="container mb-4 mb-md-5 mt-md-5 my-5">
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-8 mb-4">
            <h2>A digital home for all employees</h2>
            <p>
              If you want happy customers, you need a motivated team. For many
              years, Monday Consulting has applied this maxim when helping large
              and mid-sized enterprises succeed with their internal
              communications. Our intranet solutions give you the chance to
              fine-tune the audiences for your information, strengthen staff
              retention and turn your workforce into a community – even across
              multiple sites and locations. Our specialists make the most of
              complex structures and the integration of versatile third-party
              services to simplify your internal processes.
            </p>
            <p>
              As a Haiilo partner, the Employee Engagement Cloud we offer gives
              you all of the basic services you need to enhance your corporate
              culture and deploy multi-channel communications to your workplace.
              Consisting of four products – Stories, Share, Home and Insights –
              this is a 100% Java solution, which means our developers are not
              only familiar with the product package but also the technology it
              runs on. However complex the requirements, integrations and
              connections, we take it all in our stride. And thanks to our
              content management expertise, we also ensure that you get the most
              out of the platform and use all of its features to the full.
            </p>
            <p>
              As one of the largest providers of social intranet and staff
              communications software, Haiilo helps companies create a digital
              home for all of their employees, and provide them with outstanding
              and inspiring multi-channel communications. Worldwide, the
              platform is now used by more than 2 million employees and has been
              deployed by over 750 prestigious international clients.
            </p>
          </div>
          <div className="col-12 col-md-4 px-4 d-flex flex-column align-items-center">
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.haiilo.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="Haiilo"
              />
            </div>
          </div>
        </div>
      </div>

      <Container shape="square">
        <div className="container mt-6 mb-4 mb-md-5">
          <h2 className="col-12 text-center">
            Good reasons for Monday Consulting and Haiilo
          </h2>
          <Facts>
            <Count count="2009">
              Long-standing experience in the development of intranet platforms.
            </Count>
            <Count count="100" suffix="%">
              Our developers live the technologies they use.
            </Count>
            <Count count="50000" prefix=">" separator=".">
              Globally, more than 50,000 members of staff use an intranet
              platform developed by Monday Consulting.
            </Count>
          </Facts>
        </div>
      </Container>

      <div className="container">
        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-cross bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              1
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Multichannel</h2>
            <h3>Haiilo Stories</h3>
            <p>
              Today, a simple intranet platform is no longer enough to ensure
              that all of your information gets to the right audiences. This is
              why Haiilo developed Stories, to address employees on all of their
              preferred channels: from email to SharePoint, Slack to digital
              signage, bespoke employee apps – and more. And all multi-channel
              communications occur in real time. Less effort – more engagement.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-dots bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              2
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Variety of features</h2>
            <h3>Maximum bandwidth for team-building</h3>
            <p>
              Haiilo offers plenty of connectivity out of the box to increase
              employee engagement. Haiilo Home is the social intranet of
              tomorrow that offers employees their very own digital hangout. An
              easy-to-use platform that is also easy to extend to with
              additional integrations like Microsoft Teams. Haiilo Insights
              offers you a valuable set of metrics on employee satisfaction,
              while Haiilo Share gives your staff the chance to transform
              themselves into brand ambassadors.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-circle bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              3
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">GDPR</h2>
            <h3>Data protection-compliant communications</h3>
            <p>
              Haiilo offers you a secure platform fully compliant with EU data
              protection law (GDPR) that is still streamlined and
              straightforward to use. This modern intranet solution guarantees
              GDPR-compliant communications throughout your organization while
              shielding your sensitive internal information from unauthorized
              access by third parties.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-square bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              4
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Complex structures</h2>
            <h3>Intranet expertise</h3>
            <p>
              With Monday Consulting, you have a partner specialized in complex
              structures and the ever-growing number of integrated services.
              Thanks to our long-standing experience in developing intranet
              platforms, we can always identify the ideal solution for any
              requirements.
            </p>
          </div>
        </div>
      </div>

      <ServicesGrid title="We make sure you get ahead" />

      <div className="content-container mt-5 text-color-white text-center" />

      <ContactPerson
        headline="Entrust your Haiilo project to our experienced hands."
        text="No challenge is too big for our specialists to tackle. Talk to us today about your needs and requirements."
        contactEmail="haiilo@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="René Zoller, Haiilo Expert"
      >
        <GatsbyImage
          image={images.rene.childImageSharp.gatsbyImageData}
          alt="René Zoller"
        />
      </ContactPerson>
    </Layout>
  );
};

export default HaiiloPartnership;
